export const getFormattedDate = (date: string = "") => {
  const isWrongDateFormat = isNaN(Date.parse(date));

  if (!isWrongDateFormat) {
    const timeStamp = Date.parse(date);
    const fullDate = new Date(timeStamp);

    return `${fullDate.getDate()} / ${fullDate.getMonth() + 1} / ${fullDate.getFullYear()}`;
  }

  return date;
};

export const to2Digit = (n) => {
  return n < 10 ? `0${n}` : n;
};

export const prepareApiDate = (date: any = null, withTime: boolean = false, withZ = true) => {
  const d: any = new Date(date);
  if (d === "invalid date") {
    return date;
  }

  const year = d.getFullYear();
  const month = to2Digit(d.getMonth() + 1);
  const day = to2Digit(d.getDate());

  if (!withTime) {
    return `${year}-${month}-${day}T00:00Z`;
  }

  const h = to2Digit(d.getHours());
  const m = to2Digit(d.getMinutes());

  if (!withZ) {
    return `${year}-${month}-${day}T${h}:${m}:00`;
  }

  //2022-12-31T21:00:00.000Z
  return `${year}-${month}-${day}T${h}:${m}:00.000Z`;
};
