import React from "react";

import { Button, Modal } from "react-bootstrap";
import SecureIcon from "../../../assets/icons/secure-icon.svg";
import styles from "./style.module.scss";
import Storage from "../../../core/helpers/storage";
import { STORAGE_CONSTRAINT } from "../../../core/enums/storage";
import { useHistory } from "react-router-dom";
import { DashboardRouteUtil } from "../../../core/routes/dashboard.route";

type StatusPopupProps = {
  onHide?: () => void;
};
const UpdatePasswordConfirmation = ({ onHide }: StatusPopupProps) => {
  const history = useHistory();

  const handleOnLaterChange = () => {
    Storage.set(STORAGE_CONSTRAINT.SKIPPED_UPDATING_PASSWORD, true);
    onHide();
  };

  const handleOnAcceptChange = () => {
    history.push(DashboardRouteUtil.getDashboardResetPasswordPath());
    Storage.set(STORAGE_CONSTRAINT.SKIPPED_UPDATING_PASSWORD, true);
    onHide();
  };
  return (
    <>
      <Modal
        show={true}
        onHide={onHide}
        centered
        dialogClassName={styles.modalContent}
        contentClassName={styles.modalBody}
        className={styles.confirmMessage}
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center mt-4">
            <img src={SecureIcon} alt="Secure Image" />
            <div className="d-flex flex-column my-4">
              <h2 className={"text-md-center"}>Make your account more secure</h2>
              <span className="text-muted text-md-center">
                We recommend you change your password for enhanced security.
              </span>
            </div>

            <div className="d-flex justify-content-center w-100">
              <Button
                variant="outline-danger"
                className={"mx-3 w-25"}
                size={"lg"}
                onClick={handleOnLaterChange}
              >
                <strong>Later</strong>
              </Button>
              <Button
                variant="primary"
                size={"lg"}
                className={"mx-3 w-25"}
                onClick={handleOnAcceptChange}
              >
                <strong>Change</strong>
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpdatePasswordConfirmation;
