import { toast } from "react-toastify";
import { DashboardRouteUtil } from "../routes/dashboard.route";
import Storage from "../../core/helpers/storage";
import { STORAGE_CONSTRAINT } from "../enums/storage";
import { defineRulesFor } from "./abilities";
import { useCallback } from "react";
import { getUserProfile } from "../services/account-settings.service";
import { logout } from "../services/auth.service";

export const userAuthCheck = (error: any) => {
  if (error?.response?.status === 401) {
    (async () => {
      await logout();
      window.location.href = DashboardRouteUtil.getLoginPath();
    })();
  } else {
    toast.error(error?.response?.data?.message || "something went wrong!");
  }
};

/**
 * Save User Permissions
 * @param permissions
 * @returns {<void | any>}
 */
export const prepareUserPermissions = async (permissions) => {
  if (!permissions) {
    return null;
  }

  let groups = {};
  permissions.forEach((value) => {
    let item = value.split("_");

    let key;
    let able;

    able = item.splice(item.length - 1, 1);
    item = item.splice(1, item.length);
    key = item.join("/");

    if (groups[key]) able = [...groups[key], ...able];

    groups[key] = able;
  });

  // save session permissions on the local storage
  Storage.set(STORAGE_CONSTRAINT.USER_PERMISSIONS, groups);
};

/**
 * Handle on logged in user update ability
 * @return {Promise<any>}
 */
export const setupUserAbility = async () => {
  const user = await Storage.get(STORAGE_CONSTRAINT.USER_PROFILE);
  if (!user) {
    return;
  }
  if (!user?.permissions) {
    return;
  }
  await prepareUserPermissions([...user?.permissions]);
  delete user["permissions"];
  return defineRulesFor(user);
};

/**
 * Setup User Data
 * @return {Promise<any>}
 */
export const setupUserData = async () => {
  const res = await getUserProfile();
  Storage.set(STORAGE_CONSTRAINT.PROFILE_DATA, res);
  return setupUserAbility();
};
