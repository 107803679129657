import { HttpMethods } from "../enums/http-methods.enum";
import { PromotionFormType } from "../types/promotion.type";
import { makeRequest, baseURL } from "./http.service";

export const getPromotions = async (
  page?: number,
  params?: { keyword?: string; status?: number }
) => {
  return await makeRequest({
    url: `${baseURL}/promotions`,
    method: HttpMethods.GET,
    params: { page, ...params },
  });
};

export const getPromotionsCounts = async () => {
  return await makeRequest({
    url: `${baseURL}/promotions/counts`,
    method: HttpMethods.GET,
  });
};

export const CreateNewPromotion = async (formData: PromotionFormType) => {
  return await makeRequest({
    url: `${baseURL}/promotions`,
    method: HttpMethods.POST,
    data: formData,
  });
};

export const ViewPromotion = async (promotion_id: string) => {
  return await makeRequest({
    url: `${baseURL}/promotions/${promotion_id}`,
    method: HttpMethods.GET,
  });
};

export const DeletePromotion = async (promotion_id: string) => {
  return await makeRequest({
    url: `${baseURL}/promotions/${promotion_id}`,
    method: HttpMethods.DELETE,
  });
};

export const EditPromotion = async (promotion_id: string, formData: PromotionFormType) => {
  return await makeRequest({
    url: `${baseURL}/promotions/${promotion_id}`,
    method: HttpMethods.PUT,
    data: formData,
  });
};

// Lists
export const getPromotionServiceTypeList = async () => {
  return await makeRequest({
    url: `${baseURL}/promotions/lists/service-type`,
    method: HttpMethods.GET,
  });
};
export const getPromotionRequirementTypeList = async () => {
  return await makeRequest({
    url: `${baseURL}/promotions/lists/requirement-type`,
    method: HttpMethods.GET,
  });
};
export const getPromotionStatusList = async () => {
  return await makeRequest({
    url: `${baseURL}/promotions/lists/status`,
    method: HttpMethods.GET,
  });
};
export const getPromotionCustomerEligibility = async () => {
  return await makeRequest({
    url: `${baseURL}/promotions/lists/customer-eligibility`,
    method: HttpMethods.GET,
  });
};
export const getPromotionUsageType = async () => {
  return await makeRequest({
    url: `${baseURL}/promotions/lists/usage-type`,
    method: HttpMethods.GET,
  });
};
export const getPromotionType = async () => {
  return await makeRequest({
    url: `${baseURL}/promotions/lists/type`,
    method: HttpMethods.GET,
  });
};
