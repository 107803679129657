const dashboardPrefix = "/dashboard";

export class DashboardRouteUtil {
  public static getLoginPath(): string {
    return "/";
  }

  public static getForgetPassworPath(): string {
    return "/forget-password";
  }

  public static getSuccessSendPath(): string {
    return "/success-send";
  }

  public static getConfirmPasswordPath(): string {
    return "/password/reset";
  }

  public static getBranchResetPasswordPath(): string {
    return "/branch/confirm-password";
  }
  public static getBranchResetPasswordSuccessMobilePath(): string {
    return "/branch/reset-password?status=sucess";
  }
  public static getDashboardPath(): string {
    return `${dashboardPrefix}`;
  }

  public static getBrandInfoPath(): string {
    return `${dashboardPrefix}/brand`;
  }

  public static getAccountSettingsPath(): string {
    return `${dashboardPrefix}/account-settings`;
  }

  public static getOrdersListPath(): string {
    return `${dashboardPrefix}/orders`;
  }

  public static getOrderEditPath(): string {
    return `${dashboardPrefix}/orders/:order_id/edit`;
  }

  public static getOrderEditUrl(order_id: string): string {
    return `${dashboardPrefix}/orders/${order_id}/edit`;
  }

  public static getSellersListPath(): string {
    return `${dashboardPrefix}/sellers`;
  }

  public static getSellerCreatePath(): string {
    return `${dashboardPrefix}/sellers/create`;
  }

  public static getSellerWorkingHoursPath(): string {
    return `${dashboardPrefix}/brand/working-hours`;
  }

  public static getSellerWorkingHoursUrl(): string {
    return `${dashboardPrefix}/brand/working-hours`;
  }

  public static getSellerEditPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/edit`;
  }

  public static getSellerEditUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/edit`;
  }

  public static getSellerSectionPath(): string {
    return `${dashboardPrefix}/brand/sections`;
  }

  public static getSellerSectionUrl(): string {
    return `${dashboardPrefix}/brand/sections`;
  }

  public static getSellerBranchesUrl(): string {
    return `${dashboardPrefix}/branches`;
  }

  public static getSellerBranchesPath(): string {
    return `${dashboardPrefix}/branches`;
  }

  public static getSellerCreateBranchUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/branches/create`;
  }

  public static getSellerCreateBranchPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/branches/create`;
  }

  public static getSellerEditBranchUrl(branch_id: string): string {
    return `${dashboardPrefix}/sellers/branches/edit/${branch_id}`;
  }

  public static getSellerEditBranchPath(): string {
    return `${dashboardPrefix}/sellers/branches/edit/:branch_id`;
  }
  public static getSellerItemsPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/items`;
  }

  public static getSellerItemsUrl(seller_id: string): string {
    return `${dashboardPrefix}/sellers/${seller_id}/items`;
  }

  public static getSellerItemsListPath(): string {
    return `${dashboardPrefix}/brand/items`;
  }

  public static getSellerItemsListUrl(): string {
    return `${dashboardPrefix}/brand/items`;
  }

  public static getSellerItemsCreatePath(): string {
    return `${dashboardPrefix}/sellers/items/create`;
  }

  public static getSellerItemsCreateUrl(): string {
    return `${dashboardPrefix}/sellers/items/create`;
  }

  // Note: Duplicate is the same like create bug we add the duplicate_id as a query param
  public static getSellerItemsDuplicateUrl(duplicate_id: any): string {
    return `${dashboardPrefix}/sellers/items/create?duplicate_id=${duplicate_id}`;
  }

  public static getSellerItemsEditPath(): string {
    return `${dashboardPrefix}/sellers/items/edit/:item_id`;
  }

  public static getSellerBranchItemsEditPath(): string {
    return `${dashboardPrefix}/sellers/:seller_id/branches/:branch_id/items/edit/:item_id`;
  }

  public static getSellerBranchItemsEditUrl(
    seller_id: string,
    branch_id: string,
    item_id: string
  ): string {
    return `${dashboardPrefix}/sellers/${seller_id}/branches/${branch_id}/items/edit/${item_id}`;
  }

  public static getSellerItemsEditUrl(item_id: any): string {
    return `${dashboardPrefix}/sellers/items/edit/${item_id}`;
  }

  public static getCategoriesPath(): string {
    return `${dashboardPrefix}/categories`;
  }

  public static getOffersPath(): string {
    return `${dashboardPrefix}/marketing/vouchers`;
  }

  public static getOffersCreatePath(): string {
    return `${dashboardPrefix}/marketing/vouchers/create`;
  }
  // Marketing Module Pathes Helpers
  public static getMarketingModulePath(): string {
    return `${dashboardPrefix}/marketing`;
  }

  public static getPromorionsCreatePath(): string {
    return `${dashboardPrefix}/marketing/promotions/create`;
  }

  public static getPromorionsEditPath(): string {
    return `${dashboardPrefix}/marketing/promotions/edit/:promotion_id`;
  }
  public static getPromorionsEditUrl(promotion_id: string): string {
    return `${dashboardPrefix}/marketing/promotions/edit/${promotion_id}`;
  }

  public static getCouponsCreatePath(): string {
    return `${dashboardPrefix}/marketing/coupons/create`;
  }

  public static getCouponsEditPath(): string {
    return `${dashboardPrefix}/marketing/coupons/edit/:coupon_id`;
  }
  public static getCouponsEditUrl(coupon_id: string): string {
    return `${dashboardPrefix}/marketing/coupons/edit/${coupon_id}`;
  }

  // Customers Module Pathes Helpers
  public static getListCustomersPath(): string {
    return `${dashboardPrefix}/customers`;
  }

  // Permission Module
  public static getPermissionsModulePath(): string {
    return `${dashboardPrefix}/permissions`;
  }

  // Users Module
  public static getCreateUserModulePath(): string {
    return `${dashboardPrefix}/permissions/users/create`;
  }

  // CITIES & Countries (location management)

  public static getCitiesListPath(): string {
    return `${dashboardPrefix}/location-management/cities`;
  }

  public static getCountriesListPath(): string {
    return `${dashboardPrefix}/location-management/countries`;
  }

  public static getCitiesCreatePath(): string {
    return `${dashboardPrefix}/location-management/cities/create`;
  }

  public static getCountriesCreatePath(): string {
    return `${dashboardPrefix}/location-management/countries/create`;
  }

  public static getCitiesEditPath(): string {
    return `${dashboardPrefix}/location-management/cities/:city_id/edit`;
  }

  public static getCitiesEditUrl(city_id: string): string {
    return `${dashboardPrefix}/location-management/cities/${city_id}/edit`;
  }

  public static getCountriesEditPath(): string {
    return `${dashboardPrefix}/location-management/countries/:country_id/edit`;
  }

  public static getCountriesEditUrl(city_id: string): string {
    return `${dashboardPrefix}/location-management/countries/${city_id}/edit`;
  }
  // Subscriptions

  public static getSubscriptionsListPath(): string {
    return `${dashboardPrefix}/subscriptions`;
  }

  public static getSubscriptionsSettingsListPath(): string {
    return `${dashboardPrefix}/duration`;
  }

  public static getSubscriptionsCreatePath(): string {
    return `${dashboardPrefix}/subscriptions/create`;
  }

  public static getSubscriptionsEditPath(): string {
    return `${dashboardPrefix}/subscriptions/:subscriptionId/edit`;
  }

  public static getSubscriptionsEditUrl(subscriptionId: string): string {
    return `${dashboardPrefix}/subscriptions/${subscriptionId}/edit`;
  }

  // seller balance
  public static getSellerBalancePath(): string {
    return `${dashboardPrefix}/seller-balance`;
  }
  // seller balance branches
  public static getSellerBalanceBranchesPath(): string {
    return `${dashboardPrefix}/seller-balance/branches`;
  }

  public static getDashboardResetPasswordPath(): string {
    return `${dashboardPrefix}/reset`;
  }
}
