import { HttpMethods } from "../enums/http-methods.enum";
import { CouponFormType } from "../types/coupon.type";
import { makeRequest, baseURL } from "./http.service";

export const getCoupons = async (page?: number, params?: { keyword?: string; status?: number }) => {
  return await makeRequest({
    url: `${baseURL}/coupons`,
    method: HttpMethods.GET,
    params: { page, ...params },
  });
};
export const getCouponsCounts = async () => {
  return await makeRequest({
    url: `${baseURL}/coupons/counts`,
    method: HttpMethods.GET,
  });
};

export const DeleteCoupon = async (coupon_id: string) => {
  return await makeRequest({
    url: `${baseURL}/coupons/${coupon_id}`,
    method: HttpMethods.DELETE,
  });
};

export const CreateNewCoupon = async (formData: CouponFormType) => {
  return await makeRequest({
    url: `${baseURL}/coupons`,
    method: HttpMethods.POST,
    data: formData,
  });
};

export const ViewCoupon = async (coupon_id: string) => {
  return await makeRequest({
    url: `${baseURL}/coupons/${coupon_id}`,
    method: HttpMethods.GET,
  });
};

export const EditCoupon = async (coupon_id: string, formData: CouponFormType) => {
  return await makeRequest({
    url: `${baseURL}/coupons/${coupon_id}`,
    method: HttpMethods.PUT,
    data: formData,
  });
};

// Lists
export const getCouponsServiceTypeList = async () => {
  return await makeRequest({
    url: `${baseURL}/coupons/lists/service-type`,
    method: HttpMethods.GET,
  });
};
export const getCouponRequirementTypeList = async () => {
  return await makeRequest({
    url: `${baseURL}/coupons/lists/requirement-type`,
    method: HttpMethods.GET,
  });
};
export const getCouponStatusList = async () => {
  return await makeRequest({
    url: `${baseURL}/coupons/lists/status`,
    method: HttpMethods.GET,
  });
};
export const getCouponCustomerEligibility = async () => {
  return await makeRequest({
    url: `${baseURL}/coupons/lists/customer-eligibility`,
    method: HttpMethods.GET,
  });
};
export const getCouponUsageType = async () => {
  return await makeRequest({
    url: `${baseURL}/coupons/lists/usage-type`,
    method: HttpMethods.GET,
  });
};
export const getCouponType = async () => {
  return await makeRequest({
    url: `${baseURL}/coupons/lists/type`,
    method: HttpMethods.GET,
  });
};
