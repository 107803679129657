import { useContext, useEffect } from "react";
import Storage from "../../../core/helpers/storage";
import { STORAGE_CONSTRAINT } from "../../../core/enums/storage";
import { setupUserData } from "../../../core/helpers/auth.helpr";
import { AbilityContext } from "../../../components/can";

const UserPermissions = ({ children }) => {
  const ability = useContext(AbilityContext);

  useEffect(() => {
    (async () => {
      if (Storage.get(STORAGE_CONSTRAINT.USER_AUTH)) {
        const permissions = await setupUserData();
        ability.update(permissions);
      }
    })();

    return () => {
      destroyUserAbility();
    };
  }, []);

  /***
   * Destroy the User Abilities
   * @return {void}
   */
  const destroyUserAbility = () => {
    Storage.remove(STORAGE_CONSTRAINT.USER_PERMISSIONS);
    return ability.update([]);
  };

  return <>{children}</>;
};

export default UserPermissions;
