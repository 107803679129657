import { Link, useLocation } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { currentLang, SideText } from "../../../core/helpers/style.helper";
import logo from "../../../assets/icons/new-logo.png";
import styles from "./style.module.scss";
import { DashboardRouteUtil } from "../../../core/routes/dashboard.route";
import { SidebarType } from "../../../core/types/sidebar.type";
import { useEffect, useState } from "react";
import Storage from "../../../core/helpers/storage";
import { STORAGE_CONSTRAINT } from "../../../core/enums/storage";

export type DashboardWrapperProps = {
  children: JSX.Element;
  withoutHeader?: boolean;
  withOverlay?: boolean;
  fullOverlay?: boolean;
  title?: string;
  additionalMenu?: JSX.Element;
};

export const AppWrapper = ({ children }: DashboardWrapperProps) => {
  const { t } = useTranslation();
  const location = useLocation();

  const [activePage, setActivePage] = useState("");
  const [brandData, setBrandData] = useState(null);
  const [brandLogo, setBrandLogo] = useState(null);
  const [smallBrandLogo, setSmallBrandLogo] = useState(null);

  useEffect(() => {
    setBrandData(Storage.get(STORAGE_CONSTRAINT.SELLER_DATA));
    const pathArray = window.location.pathname.split("/");
    if (pathArray[2]) {
      setActivePage(pathArray[2]);
    } else {
      setActivePage(pathArray[1]);
    }
  }, []);

  const list: SidebarType[] = [
    {
      lable: "dashboard",
      link: DashboardRouteUtil.getDashboardPath(),
      icon: require(`../../../assets/icons/dashboard.svg`).default,
      isActive: activePage === "dashboard",
      module: "dashboard",
    },
    // {
    //   lable: "sellers",
    //   link: DashboardRouteUtil.getSellersListPath(),
    //   icon: require(`../../../assets/icons/sellers.svg`).default,
    //   isActive: location.pathname.includes("sellers"),
    //   module: "sellers",
    // },
    // {
    //   lable: "customers",
    //   link: DashboardRouteUtil.getListCustomersPath(),
    //   icon: require(`../../../assets/icons/customers.svg`).default,
    //   isActive: location.pathname.includes("customers"),
    //   module: "customers",
    // },
    {
      lable: "brand",
      link: DashboardRouteUtil.getBrandInfoPath(),
      icon: require(`../../../assets/icons/orders.svg`).default,
      isActive: activePage === "brand",
      module: "brand",
    },
    {
      lable: "branches",
      link: DashboardRouteUtil.getSellerBranchesUrl(),
      icon: require(`../../../assets/icons/orders.svg`).default,
      isActive: activePage === "dashboard/branches" || activePage === "dashboard/sellers/branches",
      module: "branches",
    },
    {
      lable: "orders",
      link: DashboardRouteUtil.getOrdersListPath(),
      icon: require(`../../../assets/icons/orders.svg`).default,
      isActive: activePage === "orders",
      module: "orders",
    },
    // {
    //   lable: "catalog",
    //   link: DashboardRouteUtil.getCategoriesPath(),
    //   icon: require(`../../../assets/icons/catalog.svg`).default,
    //   isActive: ( activePage === "categories"),
    //   module: "categories",
    // },
    {
      lable: "subscription",
      link: DashboardRouteUtil.getSubscriptionsListPath(),
      icon: require(`../../../assets/icons/date.svg`).default,
      isActive: activePage === "subscriptions",
      module: "subscriptions",
    },
    // {
    //   lable: "citiesManagement",
    //   link: DashboardRouteUtil.getCitiesListPath(),
    //   icon: require(`../../../assets/icons/cities.svg`).default,
    //   isActive: ( activePage === "location-management"),
    //   module: "cities",
    // },
    {
      lable: "seller.balance",
      link: DashboardRouteUtil.getSellerBalancePath(),
      icon: require(`../../../assets/icons/invoices.svg`).default,
      isActive: activePage === "seller-balance",
      module: "seller/balances",
    },
    // {
    //   lable: "marketing",
    //   link: DashboardRouteUtil.getMarketingModulePath(),
    //   icon: require(`../../../assets/icons/offers.svg`).default,
    //   isActive: ( activePage === "marketing"),
    //   module: "coupons",
    // },
    // {
    //   lable: "permissions",
    //   link: DashboardRouteUtil.getPermissionsModulePath(),
    //   icon: require(`../../../assets/icons/permissions.svg`).default,
    //   isActive: ( activePage === "permissions"),
    //   module: "admins",
    // },
  ];

  return (
    <>
      <Container className="d-block d-md-none">
        <Row className="align-items-center justify-content-center">
          <Col lg={10} xs={10} className="my-5 text-center">
            <img src={logo} width={100} height={100} alt="Mazaj" />
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center text-center">
          <Col lg={10} xs={10} className="my-5">
            <h2>Please use desktop to be able to view</h2>
          </Col>
        </Row>
        <div className={"overlay-container"} />
      </Container>
      <div className={`${styles.wrapper} d-md-flex d-none`}>
        <div className={`d-sm-block d-none app-side-menu ${styles.sideBar}`}>
          <Container>
            <div className="brand-container">
              <div className="brand-logo-container">
                <img
                  src={brandData?.logo_image?.path || ""}
                  alt={brandData?.[`${currentLang()}_name`]}
                  className="brand-logo"
                />
              </div>
              <div className="brand-name">{brandData?.[`${currentLang()}_name`]}</div>
            </div>
            <Row className="align-items-center justify-content-center">
              {list.map((item: SidebarType) => (
                <Col
                  key={`app-route-${item.link}`}
                  lg={10}
                  xs={10}
                  className={`mb-3 app-side-menu-item`}
                >
                  <Link to={item.link} className="text-decoration-none">
                    <Button
                      className={`${styles.btnFont} ${
                        item.isActive ? "text-primary bg-primary-light" : ""
                      } w-100 py-2 d-flex align-items-center btn-primary-light mb-3`}
                      variant=""
                    >
                      <img src={item.icon} alt={item.lable} />
                      <span className="mx-3 menu-item-label">
                        {t(`wrappers.dashboard.${item.lable}`)}
                      </span>
                    </Button>
                  </Link>
                </Col>
              ))}
            </Row>
            <div className={"overlay-container"} />
          </Container>
        </div>
        {children}
      </div>
    </>
  );
};
