import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { OppositeSideMargin, OppositeSidePadding, SideText } from "../../core/helpers/style.helper";
import userIcon from "../../assets/icons/header-user.svg";
import logoutIcon from "../../assets/icons/header-logout.svg";
import { logout } from "../../core/services/auth.service";
import { useHistory } from "react-router-dom";
import { DashboardRouteUtil } from "../../core/routes/dashboard.route";
import { prepareUserPermissions, userAuthCheck } from "../../core/helpers/auth.helpr";
import { useCallback, useContext, useEffect } from "react";
import { getUserProfile } from "../../core/services/account-settings.service";
import { AbilityContext } from "../../components/can";
import Storage from "../../core/helpers/storage";
import { STORAGE_CONSTRAINT } from "../../core/enums/storage";
import { defineRulesFor } from "../../core/helpers/abilities";

export const UserMenu = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userData = Storage.get(STORAGE_CONSTRAINT.PROFILE_DATA);

  const ability = useContext(AbilityContext);

  const handleLogout = async () => {
    try {
      await logout();
      destroyUserAbility();
      history.push(DashboardRouteUtil.getLoginPath());
    } catch (error) {
      userAuthCheck(error);
    }
  };

  const handleRedirectionToSettings = () => {
    history.push(DashboardRouteUtil.getAccountSettingsPath());
  };

  /***
   * Destroy the User Abilities
   * @return {void}
   */
  const destroyUserAbility = () => {
    Storage.remove(STORAGE_CONSTRAINT.USER_PERMISSIONS);
    return ability.update([]);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="">
        <span>{t("general.hello")}, </span>
        <strong style={OppositeSidePadding("10px")}>{userData?.first_name}</strong>
      </Dropdown.Toggle>

      <Dropdown.Menu style={SideText()}>
        <Dropdown.Item className="text-primary" onClick={handleRedirectionToSettings}>
          <img src={userIcon} alt="user" style={OppositeSideMargin("12px")} />{" "}
          {t("general.setting")}
        </Dropdown.Item>
        <Dropdown.Item
          className="text-primary"
          // onClick={() => handleLanguageChange()}
        >
          {t("general.language")}
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item className="text-primary" onClick={() => handleLogout()}>
          <img src={logoutIcon} style={OppositeSideMargin("12px")} alt="logout" />
          {t("general.logout")}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};
