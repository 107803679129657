import axiosObject from "../configs/axios.config";
import { HttpMethods } from "../enums/http-methods.enum";
import { logout } from "./auth.service";

export const baseURL = process.env.REACT_APP_BACKEND_CORE_URL;
export const sellerBranchUrl = process.env.REACT_APP_BACKEND_SELLER_BRANCH_URL;
export type MakeRequest = {
  url: string;
  method: HttpMethods;
  data?: Object | undefined;
  params?: Object | undefined;
  headers?: Object | undefined;
};

export const makeRequest: any = async (req: MakeRequest) => {
  const { url, method, data, params, headers } = req;

  return new Promise((resolve, reject) => {
    axiosObject({
      url,
      method,
      data,
      params,
      headers,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch(async (error) => {
        if (error?.response?.status === 401) {
          await logout(false);
          window.location.href = "/";
          return reject(error);
        }
        reject(error);
      });
  });
};
