import { HttpMethods } from "../enums/http-methods.enum";
import { UserLoginType } from "../types/auth.type";
import { makeRequest, baseURL, sellerBranchUrl } from "./http.service";
import Storage from "../helpers/storage";
import { STORAGE_CONSTRAINT } from "../enums/storage";

export const login = async (data: UserLoginType) => {
  const res = await makeRequest({
    url: `${baseURL}/login`,
    method: HttpMethods.POST,
    data,
  });
  return res;
};

export const logout = async (isAuth = true) => {
  if (isAuth) {
    await makeRequest({
      url: `${baseURL}/logout`,
      method: HttpMethods.POST,
    });
  }
  Storage.remove(STORAGE_CONSTRAINT.USER_AUTH);
  Storage.remove(STORAGE_CONSTRAINT.TOKEN);
  Storage.remove(STORAGE_CONSTRAINT.REFRESH_TOKEN);
  Storage.remove(STORAGE_CONSTRAINT.PROFILE_DATA);
  Storage.remove(STORAGE_CONSTRAINT.SKIPPED_UPDATING_PASSWORD);
  Storage.remove(STORAGE_CONSTRAINT.USER_PERMISSIONS);
};

export const forgetPassword = async (email: string) => {
  const res = await makeRequest({
    url: `${baseURL}/password/email`,
    method: HttpMethods.POST,
    data: { email },
  });
  return res;
};

export const resetPassword = async (data: any) => {
  return await makeRequest({
    url: `${baseURL}/password/reset/email`,
    method: HttpMethods.POST,
    data,
  });
};
export const branchResetPassword = async (data: any) => {
  return await makeRequest({
    url: `${sellerBranchUrl}/password/reset/email`,
    method: HttpMethods.POST,
    data,
  });
};
export const refreshToken = async () => {
  const refresh_token = Storage.get(STORAGE_CONSTRAINT.REFRESH_TOKEN);
  if (refresh_token) {
    const res = await makeRequest({
      url: `${baseURL}/refresh-token`,
      method: HttpMethods.POST,
      data: {
        refresh_token,
      },
    });
    return res;
  }
};

export const myProfileResetPassword = async (data: any) => {
  return await makeRequest({
    url: `${baseURL}/my-profile/reset-password`,
    method: HttpMethods.PUT,
    data,
  });
};
