import React from "react";
import { Route } from "react-router-dom";
import { withAuth } from "../core/helpers/withAuth";
import { withSuspense } from "../core/helpers/withSuspense";
import { DashboardRouteUtil } from "../core/routes/dashboard.route";

// Lazy Loaded Modules (Markting Module)
const ListCustomersPage = React.lazy(() => import(`../pages/dashboard/customers/list`));
// It's corresponding array routes
export const CustomersRoutes: JSX.Element[] = [
  <Route
    exact
    key="list-customers"
    path={DashboardRouteUtil.getListCustomersPath()}
    component={() => withAuth(withSuspense(ListCustomersPage))}
  />,
];
