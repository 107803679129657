type HeaderProps = {
  header: (string | JSX.Element)[];
};

export default function Header({ header }: HeaderProps): JSX.Element {
  return (
    <tr>
      {header.map((h, index) => (
        <th key={index}><span style={{ whiteSpace: 'nowrap' }}>{h}</span></th>
      ))}
    </tr>
  );
}
