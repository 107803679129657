import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { withAuth } from "./core/helpers/withAuth";
import { withSuspense } from "./core/helpers/withSuspense";
import { DashboardRouteUtil } from "./core/routes/dashboard.route";
import MarketingModule from "./pages/dashboard/marketing";
import {
  branchesRoutes,
  MarketingRoutes,
  CustomersRoutes,
  PermissionsRoutes,
  SellerBalance,
  CitiesCountiesRoutes,
  authRoutes,
} from "./routes/index.routing";
import { SubscriptionsRoutes } from "./routes/subscription.routing";

const OrdersPage = React.lazy(() => import("./pages/dashboard/order/list"));

const OrderEditPage = React.lazy(() => import("./pages/dashboard/order/edit"));

const DashboardShowPage = React.lazy(() => import("./pages/dashboard/dashboard/show"));

const SellersPage = React.lazy(() => import("./pages/dashboard/seller/list"));

const SellerSectionsPage = React.lazy(() => import("./pages/dashboard/seller/section/list"));

const EditSellerPage = React.lazy(() => import("./pages/dashboard/seller/edit"));

const WorkingHoursPage = React.lazy(() => import("./pages/dashboard/seller/working-hours"));

const CategoreisListPage = React.lazy(() => import("./pages/dashboard/category/list"));

const ItemsListPage = React.lazy(() => import("./pages/dashboard/seller/items/list"));

const CreateVoucherPage = React.lazy(() => import("./pages/dashboard/voucher/create"));

const EditItemPage = React.lazy(() => import("./pages/dashboard/seller/items/edit"));
const CreateItemPage = React.lazy(() => import("./pages/dashboard/seller/items/create"));
function AppRouting() {
  const dashboardRoutes: JSX.Element[] = [
    <Route
      exact
      key="dashboard"
      path={DashboardRouteUtil.getDashboardPath()}
      component={() => withAuth(withSuspense(DashboardShowPage))}
    />,
    <Route
      exact
      key="orders"
      path={DashboardRouteUtil.getOrdersListPath()}
      component={() => withAuth(withSuspense(OrdersPage))}
    />,
    <Route
      exact
      key="order-edit"
      path={DashboardRouteUtil.getOrderEditPath()}
      component={() => withAuth(withSuspense(OrderEditPage))}
    />,
    <Route
      exact
      key="sellers"
      path={DashboardRouteUtil.getSellersListPath()}
      component={() => withAuth(withSuspense(SellersPage))}
    />,
    <Route
      exact
      key="brand-details"
      path={DashboardRouteUtil.getBrandInfoPath()}
      component={() => withAuth(withSuspense(EditSellerPage))}
    />,
    <Route
      exact
      key="seller-working-hours"
      path={DashboardRouteUtil.getSellerWorkingHoursPath()}
      component={() => withAuth(withSuspense(WorkingHoursPage))}
    />,
    <Route
      exact
      key="brand-sections"
      path={DashboardRouteUtil.getSellerSectionPath()}
      component={() => withAuth(withSuspense(SellerSectionsPage))}
    />,
    <Route
      exact
      key="seller-items-list"
      path={DashboardRouteUtil.getSellerItemsListPath()}
      component={() => withAuth(withSuspense(ItemsListPage))}
    />,
    <Route
      exact
      key="categoreis"
      path={DashboardRouteUtil.getCategoriesPath()}
      component={() => withAuth(withSuspense(CategoreisListPage))}
    />,
    <Route
      exact
      key="offers"
      path={DashboardRouteUtil.getMarketingModulePath()}
      component={() => withAuth(withSuspense(MarketingModule))}
    />,
    <Route
      exact
      key="voucher-create"
      path={DashboardRouteUtil.getOffersCreatePath()}
      component={() => withAuth(withSuspense(CreateVoucherPage))}
    />,
    <Route
      exact
      key="seller-items-edit"
      path={DashboardRouteUtil.getSellerItemsEditPath()}
      component={() => withAuth(withSuspense(EditItemPage))}
    />,

    <Route
      exact
      key="seller-items-create"
      path={DashboardRouteUtil.getSellerItemsCreatePath()}
      component={() => withAuth(withSuspense(CreateItemPage))}
    />,
  ];

  const commonRoutes = [
    <Redirect path="*" to={DashboardRouteUtil.getDashboardPath()} exact key="redirect" />,
  ];

  const finalRoutes = [
    ...authRoutes,
    ...dashboardRoutes,
    ...branchesRoutes,
    ...MarketingRoutes,
    ...PermissionsRoutes,
    ...SellerBalance,
    ...CustomersRoutes,
    ...CitiesCountiesRoutes,
    ...SubscriptionsRoutes,
    ...commonRoutes,
  ];

  return (
    <React.Suspense fallback={<></>}>
      <Switch>{finalRoutes}</Switch>
    </React.Suspense>
  );
}

export default AppRouting;
