import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { DashboardRouteUtil } from "../../../../../core/routes/dashboard.route";
import { SideMargin, SidePadding, SidePosition } from "../../../../../core/helpers/style.helper";
import { PromotionStatus } from "../../../../../core/enums/promotion-status.enum";
import { CouponListType } from "../../../../../core/types/coupon.type";
import { SellerStatus } from "../../../../../components/sellerStatus";
import { getPromotionStatusColor } from "../../../../../core/helpers/list.helper";
import SharedTable from "../../../../../components/table";
import { Variant } from "../../../../../core/enums/variants.enum";
import { PagesType } from "../../../../../core/types/pagination.type";
import styles from "./style.module.scss";

import noCouponsPlaceholder from "../../../../../assets/icons/no-coupons.svg";
import editIcon from "../../../../../assets/icons/edit.svg";
import deleteIcon from "../../../../../assets/icons/delete.svg";
import xIcon from "../../../../../assets/icons/close.svg";
import searchIcon from "../../../../../assets/icons/search.svg";
import { userAuthCheck } from "../../../../../core/helpers/auth.helpr";
import {
  DeleteCoupon,
  getCoupons,
  getCouponsCounts,
} from "../../../../../core/services/coupons.service";
import SearchField from "../../../../../components/search-field";

export default function CouponsList() {
  const [filter, setFilter] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [counts, setCounts] = useState<{ total: number; status_label: string }[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState(false);
  const [secondLoader, setSecondLoader] = useState(false);
  const [paginationsObj, setPaginationsObj] = useState<PagesType>();
  const [tableElements, setTableElements] = useState<JSX.Element[][]>([]);
  const [list, setList] = useState<CouponListType[]>([]);
  const { t } = useTranslation();

  // const handleDeleteCoupon = async (id: string) => {
  //   setIsLoading(true);
  //   try {
  //     await DeleteCoupon(id);

  //     toast.success("Coupon Deleted Successfully");
  //     fetchData();
  //   } catch (error) {
  //     userAuthCheck(error);
  //   }
  // };

  const setDisplayElemnts = useCallback((data: CouponListType[]) => {
    const elements = data.map((item: CouponListType) => {
      let start_at = new Date(item.start_at);
      let end_at = new Date(item.end_at);
      return [
        <a>{item.id}</a>,
        <a>{item.en_coupon}</a>,
        <>
          {item.total_uses}/{item.max_uses} used
        </>,
        <>{item.type_label}</>,
        <>{item.discount_value ? item.discount_value + " %" : ""}</>,
        <>
          {format(start_at, "MMMM d")} - {format(end_at, "MMMM d")}
        </>,
        <>
          <SellerStatus
            text={item.status_label}
            color={getPromotionStatusColor(item.status_label)}
          />
        </>,
        <div className="mx-auto">
          <Link to={DashboardRouteUtil.getCouponsEditUrl(item.id + "")}>
            <img src={editIcon} alt="edit" />
          </Link>
          <Button variant="">
            <img src={deleteIcon} alt="delete" />
          </Button>
        </div>,
      ];
    });

    setTableElements(elements);
  }, []);

  const handleFilterPromotions = (dataFilter: number) => {
    setCurrentPage(1);
    setFilter(dataFilter);
  };

  const handleSearchChange = (searchValue: string) => {
    setCurrentPage(1);
    setSearchTerm(searchValue);
  };

  const fetchData = useCallback(async () => {
    searchTerm === "" && filter === -1 ? setIsLoading(true) : setSecondLoader(true);
    try {
      const res: { data: CouponListType[]; meta: PagesType } = await getCoupons(currentPage, {
        ...(filter !== -1 && { status: filter }),
        ...(searchTerm !== "" && { keyword: searchTerm }),
      });
      setDisplayElemnts(res.data);
      setPaginationsObj(res.meta);
      setList(res.data);
    } catch (error) {
      userAuthCheck(error);
    } finally {
      setIsLoading(false);
      setSecondLoader(false);
    }
  }, [currentPage, setDisplayElemnts, filter, searchTerm]);

  useEffect(() => {
    fetchData();
    return () => {
      setTableElements([]);
      setList([]);
    };
  }, [fetchData]);

  const fetchCount = useCallback(async () => {
    const counts = await getCouponsCounts();
    setCounts(counts.filter((item: { status: number | null }) => item.status));
  }, []);

  useEffect(() => {
    fetchCount();
  }, []);

  const header = [
    t("table.header.promotion.id"),
    t("table.header.promotion.name"),
    t("table.header.promotion.usage"),
    t("table.header.promotion.type"),
    t("table.header.promotion.value"),
    t("table.header.promotion.validity"),
    t("table.header.status"),
    t("table.header.actions"),
  ];
  return (
    <Container className="h-100">
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center w-100 h-100">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : list.length > 0 || searchTerm !== "" || filter !== -1 ? (
        <Container className="mt-5 px-5">
          <Row className="align-items-center justify-content-between">
            <Col lg={4} md={8} xs={12} className="mb-3"></Col>
            <Col lg={8} md={8} xs={12} className="mb-3">
              <div
                className={`${styles.statistics} d-flex flex-wrap align-items-center justify-content-end`}
              >
                <Link to={DashboardRouteUtil.getCouponsCreatePath()}>
                  <Button variant="primary" className="px-3 py-1" style={SideMargin("10px")}>
                    {t("pages.marketing.coupons.placeholder.call.to.action")}
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="bg-white justify-content-center shadow rounded pb-3">
            <Row className="align-items-center justify-content-center">
              <Col lg={12} className="border-bottom">
                <Button
                  variant=""
                  onClick={() => handleFilterPromotions(-1)}
                  className={`rounded-0 ${styles.filterBtn} ${filter === -1 && styles.active}`}
                >
                  {t("filter.sellers.all")}
                </Button>
                {counts &&
                  counts.map((item: any) => (
                    <Button
                      key={item.status}
                      variant=""
                      onClick={() => handleFilterPromotions(item.status)}
                      className={`rounded-0 d-inline-flex ${styles.filterBtn} ${
                        filter === item.status && styles.active
                      }`}
                    >
                      {item.status_label}
                      <SellerStatus
                        text={item.total}
                        color={
                          item.status_label === PromotionStatus.ACTIVE
                            ? Variant.SUCCESS
                            : item.status_label === PromotionStatus.SCHEDULED
                            ? Variant.WARNING
                            : Variant.DANGER
                        }
                      />
                    </Button>
                  ))}
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
              <Col lg={12} className="my-3 position-relative">
                <SearchField
                  placeholder="Search Coupons Name"
                  value={searchTerm}
                  handleOnSearch={handleSearchChange}
                />
              </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
              <Col lg={12} xs={8}>
                {secondLoader ? (
                  <div className="my-5 d-flex align-items-center justify-content-center w-100 h-100">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : tableElements.length > 0 && paginationsObj ? (
                  <SharedTable
                    header={header}
                    data={tableElements}
                    page={paginationsObj?.current_page}
                    pagesCount={Math.ceil(paginationsObj?.total / paginationsObj.per_page)}
                    pageClicked={(page: number) => setCurrentPage(page)}
                  />
                ) : (
                  <div className="text-center py-5">
                    <img src={noCouponsPlaceholder} alt="No Promotions" />
                    <p className="text-muted mt-5">{t("pages.order.empty.filter")}</p>
                  </div>
                )}
              </Col>
            </Row>
          </Row>
        </Container>
      ) : (
        <Container className="h-100 d-flex align-items-center px-5">
          <Row className="w-100 align-items-center justify-content-center text-center">
            <Col lg={6} xs={8} className="my-3">
              <img src={noCouponsPlaceholder} alt="No Coupons" />
              <h2 className="mt-5">{t("pages.marketing.coupons.placeholder.title")}</h2>
              <p className="text-muted">{t("pages.marketing.coupons.placeholder.body")}</p>
              <Link to={DashboardRouteUtil.getCouponsCreatePath()}>
                <Button variant="primary" className="px-3 py-2">
                  {t("pages.marketing.coupons.placeholder.call.to.action")}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
}
