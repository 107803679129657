import { AbilityBuilder } from "@casl/ability";
import Storage from "./storage";
import { STORAGE_CONSTRAINT } from "../enums/storage";

export const defineRulesFor = (user: any) => {
  //@ts-ignore
  const { can, rules } = new AbilityBuilder();
  const permissions = Storage.get(STORAGE_CONSTRAINT.USER_PERMISSIONS);
  const keys: Array<string> = Object.keys(permissions);
  for (let i = 0; i < keys.length; i++) {
    const action = permissions[keys[i]];
    const model = keys[i];

    can(action, model, [model], user);
  }
  return rules;
};
