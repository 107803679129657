export enum STORAGE_CONSTRAINT {
  USER_PERMISSIONS = "USER_PERMISSIONS",
  USER_PROFILE = "profileData",
  USER_AUTH = "userAuth",
  TOKEN = "token",
  REFRESH_TOKEN = "refreshToken",
  PROFILE_DATA = "profileData",
  MIN_SIDE_NAV = "MIN_SIDE_NAV",
  SELLER_DATA = "SELLER_DATA",
  REMEMBER_TOKEN = "REMEMBER_TOKEN",
  APP_TYPE = "APP_TYPE",
  APP_VERSION = "SELLER_1",
  SKIPPED_UPDATING_PASSWORD = "SKIPPED_UPDATING_PASSWORD",
}
