import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { OppositeSideText } from "../../../core/helpers/style.helper";
import { UserMenu } from "../../../components/dropdown";
import menuLogo from "../../../assets/icons/menu.svg";
import styles from "./style.module.scss";
import { STORAGE_CONSTRAINT } from "../../../core/enums/storage";
import Storage from "../../../core/helpers/storage";
import UpdatePasswordConfirmation from "../../../components/modal/update-password";

export type DashboardWrapperProps = {
  children: JSX.Element;
  withoutHeader?: boolean;
  withOverlay?: boolean;
  fullOverlay?: boolean;
  title?: string;
  additionalMenu?: JSX.Element;
};

export const DashboardWrapper = ({
  children,
  withoutHeader,
  fullOverlay,
  title = "",
  additionalMenu,
}: DashboardWrapperProps) => {
  const [isConfirmUpdatePassword, setIsConfirmUpdatePassword] = useState(false);

  useEffect(() => {
    const root: any = document.querySelector("body");
    root.classList.remove("overlay");
    if (fullOverlay) {
      root.classList.add("overlay");
    }
  }, [fullOverlay]);

  useEffect(() => {
    prepareSideMenu(Storage.get(STORAGE_CONSTRAINT.MIN_SIDE_NAV));

    const data = Storage.get(STORAGE_CONSTRAINT.PROFILE_DATA);
    const isUserSkippedUpdatedPassword = Storage.get(STORAGE_CONSTRAINT.SKIPPED_UPDATING_PASSWORD);

    setIsConfirmUpdatePassword(!data?.last_password_updated_at && !isUserSkippedUpdatedPassword);
  }, []);

  const handleOnToggleMenu = () => {
    const isSideMenuMin: boolean = !!document.querySelector("body.min-side-menu");
    prepareSideMenu(!isSideMenuMin);
  };

  const prepareSideMenu = (isSideMenuMin = false) => {
    const root: any = document.querySelector("body");

    if (isSideMenuMin) root.classList.add("min-side-menu");
    else root.classList.remove("min-side-menu");
    Storage.set(STORAGE_CONSTRAINT.MIN_SIDE_NAV, isSideMenuMin);
  };

  return (
    <>
      <div className={`${styles.wrapper} d-md-flex d-none`}>
        <div className={"overlay-container"} />
        <div className={`${styles.wrapperWidth} d-flex flex-column main-container`}>
          {!withoutHeader && (
            <div className={`w-100 border-bottom d-flex align-items-center px-3 ${styles.header}`}>
              <Container>
                <Row className="align-items-center justify-content-between">
                  <Col lg={8} xs={6}>
                    <div className="d-flex align-items-center">
                      <Button variant="" onClick={handleOnToggleMenu}>
                        <img src={menuLogo} alt="menu" />
                      </Button>
                      <h4 className="m-0 mx-3">
                        <strong>{title}</strong>
                      </h4>
                      {additionalMenu}
                    </div>
                  </Col>
                  <Col lg={4} xs={6} style={OppositeSideText()}>
                    <UserMenu />
                  </Col>
                </Row>
              </Container>
            </div>
          )}
          {children}

          {!!isConfirmUpdatePassword && (
            <UpdatePasswordConfirmation onHide={() => setIsConfirmUpdatePassword(false)} />
          )}
        </div>
      </div>
    </>
  );
};
