import { Button, Col, Container, Row } from "react-bootstrap";
import styles from "./styles.module.scss";

export type BorderTabsProps = {
  Tabs: string[];
  activeTab: string;
  onChangeTab: Function;
  primary: boolean;
  disabled: boolean;
};

export default function BorderTabs({
  Tabs,
  activeTab,
  onChangeTab,
  primary,
  disabled,
}: BorderTabsProps) {
  const TabsELements: JSX.Element[] = Tabs.map((tab, idx) => (
    <Button
      onClick={() => {
        if (!disabled) onChangeTab(tab);
      }}
      key={idx}
      variant=""
      className={`rounded-0 ${primary ? styles.filterBtn : styles.subFilterBtn} ${
        tab === activeTab ? (primary ? styles.active : styles.subActive) : ""
      }`}
    >
      {tab}
    </Button>
  ));

  return (
    <div className="border-bottom">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg={12}>{TabsELements}</Col>
        </Row>
      </Container>
    </div>
  );
}
